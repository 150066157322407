// Snippet for showing a notification
// Returns true if success, otherwise false
// Snippet for showing a notification
// Returns true if success, otherwise false

const toastSettings = {
  position: 'bottom-center',
  timeout: 3000
}

export const notify = (instance) => {
  /* Notifiction handling */
  if (instance.notification && instance.notification.error) {
    // Check docs for all the styling options
    instance.$toast.error(instance.notification.msg, toastSettings)
    instance.clearNotifications()
    return false
  }

  if (instance.notification && instance.notification.success) {
    instance.$toast.success(instance.notification.msg, toastSettings)
    instance.clearNotifications()
    return true
  }
}

<template>
<v-main>
    <v-container fluid fill-height>
    <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
        <v-card class='elevation-12'>
            <v-toolbar dark color='primary'>
            <v-toolbar-title>Admin inlogg</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
            <v-form ref='form' lazy-validation>
                <v-text-field
                prepend-icon='mdi-at'
                name='email'
                v-model='email'
                label='Email'
                type='text'
                ></v-text-field>
                <v-text-field
                id='password'
                prepend-icon='mdi-lock'
                name='password'
                v-model='password'
                label='Kod'
                type="password"
                ></v-text-field>
            </v-form>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='accent' @click='handleSubmit'>Logga in</v-btn>
            </v-card-actions>
        </v-card>
        </v-flex>
    </v-layout>
    </v-container>

    <div v-if='loading' class='center'>
    <vue-spinner />
    </div>
</v-main>
</template>

<style>
.center {
/* Center vertically and horizontally */
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import Spinner from 'vue-simple-spinner'
import { notify } from '../../utils/notification'

export default {
  name: 'Login',
  components: {
    vueSpinner: Spinner
  },
  data: () => ({
    email: '',
    password: ''
  }),
  methods: {
    ...mapActions(['loginUser', 'clearNotifications', 'isAuth']),
    async handleSubmit () {
      await this.loginUser({ email: this.email, password: this.password })
      // await this.loginUser({email: 'natsak@gmail.com', password: 'test123'})

      // Notification
      const success = notify(this)
      if (success) {
        // If the user tried to access a page but wasn't logged in,
        // then redirect them to that page. Otherwise go to admin.
        this.$router.push(this.$route.query.redirect || '/admin')
      }
    }
  },
  computed: mapGetters(['user', 'loading', 'notification'])
}
/* Alternative way of communicating with the store
this.$store.dispatch('registerUser', {
email: this.email,
password: this.password
})
console.log(this.$store);
*/
</script>
